<template>
  <div>
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>
    <div class="mb-8">
      <import-excel :onSuccess="loadDataInTable"/>
    </div>
    <vx-card v-if="tableData.length && header.length">
      <vs-table stripe pagination :max-items="20" search :data="tableData">
        <template slot="header">
          <h4>{{ sheetName }}</h4>
        </template>

        <template slot="thead">
          <vs-th :sort-key="heading" v-for="heading in header" :key="heading">{{ heading }}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="col" v-for="(col, indexcol) in data[indextr]" :key="indexcol + col">
              {{ col }}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-button @click="importData" class="mr-6 mt-5">Importar</vs-button>
    </vx-card>
  </div>
</template>

<script>
import ImportExcel from '@/components/excel/ImportExcel.vue'

import {db, FieldValue, auth} from '@/firebase/firebaseConfig'

export default {
  name: 'ImportProducts',
  components: {
    ImportExcel
  },
  data() {
    return {
      tableData: [],
      header: [],
      sheetName: '',
      initProgress: false,
      warehouses: [],
      warehouse: {},
      brands: [],
      categories: []
    }
  },
  async created() {
    await this.loadBrands()
  },
  methods: {
    loadDataInTable({results, header, meta}) {
      this.header = header
      this.tableData = results
      this.sheetName = meta.sheetName
    },
    /**
     * Save data in firestore
     * @returns {Promise<void>}
     */
    async importData() {
      try {
        this.initProgress = true
        // Write products
        for (let data of this.tableData) {
          // Brand
          let brand = {}
          this.brands.forEach((b) => {
            if (b.name.toLowerCase() === data.BRAND.toLowerCase()) {
              brand = b
            }
          })
          let obj = {
            name: this.capitalize(data.NAME),
            sku: data.SKU ? data.SKU : '',
            price: data.PRICE ? parseFloat((parseFloat(data.PRICE) * 1.20).toFixed(0)) : 0,
            purchasePrice: data.PRICE ? parseFloat(data.PRICE) : 0,
            state: true,
            stock: 0,
            brand: brand || {},
            category: {},
            uid: auth.currentUser.uid,
            createdAt: FieldValue.serverTimestamp()
          }
          await db.collection('products').add({
            ...obj
          })
        }
        //
        this.$vs.notify({
          color: 'success',
          title: 'Importacion de productos',
          text: 'Importación realizada correctamente.'
        })
        this.initProgress = false
      } catch (e) {
        this.initProgress = false
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    /**
     * Load categories
     * @returns {Promise<void>}
     */
    async loadCategories() {
      const querySnap = await db.collection("categories").orderBy('createdAt', 'desc').get()
      querySnap.forEach((doc) => {
        let category = {
          id: doc.id,
          name: doc.data().name
        }
        this.categories.push(category)
      })
    },
    /**
     * Load brands
     * @returns {Promise<void>}
     */
    async loadBrands() {
      const querySnapshot = await db.collection("brands").orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let brand = {
          id: doc.id,
          name: doc.data().name
        }
        this.brands.push(brand)
      })
    },
    capitalize(word) {
      return word[0].toUpperCase() + word.toLowerCase().slice(1);
    }
  }
}
</script>
